import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { getLogger as n, isFollowingApiVersionTagFormat as i, getApiVersionTag as t } from "./telemetry.js";
import { isSdkError as o, ErrorCode as s } from "../public/interfaces.js";
import { latestRuntimeApiVersion as r } from "../public/runtime.js";
import { isSerializable as a } from "../public/serializable.interface.js";
import { UUID as d } from "../public/uuidObject.js";
import { version as c } from "../public/version.js";
import { GlobalVars as u } from "./globalVars.js";
import { callHandler as l } from "./handlers.js";
import g from "./hostToAppTelemetry.js";
import { serializeMessageRequest as p, deserializeMessageResponse as f, deserializeMessageRequest as m, serializeMessageResponse as w } from "./messageObjects.js";
import { tryPolyfillWithNestedAppAuthBridge as h } from "./nestedAppAuthUtils.js";
import { getCurrentTimestamp as W, ssrSafeWindow as v } from "./utils.js";
import { validateOrigin as M } from "./validOrigins.js";
const b = n("communication");
class y {}
class k {}
function I(n, i) {
  if (k.messageListener = n => function (n) {
    return e(this, void 0, void 0, function* () {
      if (!n || !n.data || "object" != typeof n.data) return void _("Unrecognized message format received by app, message being ignored. Message: %o", n);
      const e = n.source || n.originalEvent && n.originalEvent.source,
        i = n.origin || n.originalEvent && n.originalEvent.origin;
      return H(e, i).then(o => {
        o ? (function (e, n) {
          u.isFramelessWindow || y.parentWindow && !y.parentWindow.closed && e !== y.parentWindow ? y.childWindow && !y.childWindow.closed && e !== y.childWindow || (y.childWindow = e, y.childOrigin = n) : (y.parentWindow = e, y.parentOrigin = n);
          y.parentWindow && y.parentWindow.closed && (y.parentWindow = null, y.parentOrigin = null);
          y.childWindow && y.childWindow.closed && (y.childWindow = null, y.childOrigin = null);
          te(y.parentWindow), te(y.childWindow);
        }(e, i), e === y.parentWindow ? G(n) : e === y.childWindow && function (e) {
          if ("id" in e.data && "func" in e.data) {
            const n = m(e.data),
              [i, o] = l(n.func, n.args);
            i && void 0 !== o ? (X("Returning message %s from child back to child, action: %s.", ae(n), n.func), se(n.id, n.uuid, Array.isArray(o) ? o : [o])) : (X("Relaying message %s from child to parent, action: %s. Relayed message will have a new id.", ae(n), n.func), N(t("v2", "tasks.startTask"), n.func, n.args, (...e) => {
              if (y.childWindow) {
                const i = e.pop();
                X("Message from parent being relayed to child, id: %s", ae(n)), se(n.id, n.uuid, e, i);
              }
            }));
          }
        }(n)) : _("Message being ignored by app because it is either coming from the current window or a different window with an invalid origin, message: %o, source: %o, origin: %o", n, e, i);
      });
    });
  }(n), y.currentWindow = y.currentWindow || v(), y.parentWindow = y.currentWindow.parent !== y.currentWindow.self ? y.currentWindow.parent : y.currentWindow.opener, y.topWindow = y.currentWindow.top, (y.parentWindow || n) && y.currentWindow.addEventListener("message", k.messageListener, !1), !y.parentWindow) {
    const e = y.currentWindow;
    if (!e.nativeInterface) return Promise.reject(new Error("Initialization Failed. No Parent window found."));
    u.isFramelessWindow = !0, e.onNativeMessage = G;
  }
  try {
    return y.parentOrigin = "*", S(i, "initialize", [c, r, n]).then(([e, n, i, t]) => (h(t, y.currentWindow, {
      onMessage: q,
      sendPostMessage: U
    }), {
      context: e,
      clientType: n,
      runtimeConfig: i,
      clientSupportedSDKVersion: t
    }));
  } finally {
    y.parentOrigin = null;
  }
}
function T() {
  y.currentWindow && y.currentWindow.removeEventListener("message", k.messageListener, !1), y.currentWindow = null, y.parentWindow = null, y.parentOrigin = null, y.childWindow = null, y.childOrigin = null, k.parentMessageQueue = [], k.childMessageQueue = [], k.nextMessageId = 0, k.callbacks.clear(), k.promiseCallbacks.clear(), k.portCallbacks.clear(), k.legacyMessageIdsToUuidMap = {}, g.clearMessages();
}
function R(e, n, ...i) {
  return S(e, n, i).then(([e]) => e);
}
function E(e, n, ...i) {
  return S(e, n, i).then(([e, n]) => {
    if (!e) throw new Error(n);
  });
}
function O(e, n, i, ...t) {
  return S(e, n, t).then(([e, n]) => {
    if (!e) throw new Error(n || i);
  });
}
function j(e, n, ...i) {
  return S(e, n, i).then(([e, n]) => {
    if (e) throw e;
    return n;
  });
}
function S(e, n, t = void 0) {
  if (!i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  return new Promise(i => {
    const o = L(e, n, t);
    var s;
    i((s = o.uuid, new Promise(e => {
      k.promiseCallbacks.set(s, e);
    })));
  });
}
function A(e) {
  return e.map(e => a(e) ? e.serialize() : e);
}
function P(n, i, t, r, a) {
  var d;
  return e(this, void 0, void 0, function* () {
    const e = A(i),
      [c] = yield S(r, n, e);
    if (a && a(c) || !a && o(c)) throw new Error(`${c.errorCode}, message: ${null !== (d = c.message) && void 0 !== d ? d : "None"}`);
    if (t.validate(c)) return t.deserialize(c);
    throw new Error(`${s.INTERNAL_ERROR}, message: Invalid response from host - ${JSON.stringify(c)}`);
  });
}
function C(n, i, t, r) {
  var a;
  return e(this, void 0, void 0, function* () {
    const e = A(i),
      [d] = yield S(t, n, e);
    if (r && r(d) || !r && o(d)) throw new Error(`${d.errorCode}, message: ${null !== (a = d.message) && void 0 !== a ? a : "None"}`);
    if (void 0 !== d) throw new Error(`${s.INTERNAL_ERROR}, message: Invalid response from host`);
  });
}
function x(e, n, t = void 0) {
  if (!i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  const o = L(e, n, t);
  return s = o.uuid, new Promise((e, n) => {
    k.portCallbacks.set(s, (i, t) => {
      i instanceof MessagePort ? e(i) : n(t && t.length > 0 ? t[0] : new Error("Host responded without port or error details."));
    });
  });
  var s;
}
function N(e, n, t, o) {
  let s;
  if (t instanceof Function ? o = t : t instanceof Array && (s = t), !i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  const r = L(e, n, s);
  o && k.callbacks.set(r.uuid, o);
}
k.parentMessageQueue = [], k.childMessageQueue = [], k.topMessageQueue = [], k.nextMessageId = 0, k.callbacks = new Map(), k.promiseCallbacks = new Map(), k.portCallbacks = new Map(), k.legacyMessageIdsToUuidMap = {};
const $ = b.extend("sendNestedAuthRequestToTopWindow");
function U(e) {
  const n = $,
    i = y.topWindow,
    t = function (e) {
      const n = k.nextMessageId++,
        i = new d();
      return k.legacyMessageIdsToUuidMap[n] = i, {
        id: n,
        uuid: i,
        func: "nestedAppAuth.execute",
        timestamp: Date.now(),
        monotonicTimestamp: W(),
        args: [],
        data: e
      };
    }(e);
  return n("Message %s information: %o", ae(t), {
    actionName: t.func
  }), z(i, t);
}
const Q = b.extend("sendRequestToTargetWindowHelper");
function z(e, n) {
  const i = Q,
    t = ne(e),
    o = p(n);
  if (u.isFramelessWindow) y.currentWindow && y.currentWindow.nativeInterface && (i("Sending message %s to %s via framelessPostMessage interface", ae(o), t), y.currentWindow.nativeInterface.framelessPostMessage(JSON.stringify(o)));else {
    const s = ee(e);
    e && s ? (i("Sending message %s to %s via postMessage", ae(o), t), e.postMessage(o, s)) : (i("Adding message %s to %s message queue", ae(o), t), Z(e).push(n));
  }
  return n;
}
const F = b.extend("sendMessageToParentHelper");
function L(e, n, i) {
  const t = F,
    o = y.parentWindow,
    s = function (e, n, i) {
      const t = k.nextMessageId++,
        o = new d();
      return k.legacyMessageIdsToUuidMap[t] = o, {
        id: t,
        uuid: o,
        func: n,
        timestamp: Date.now(),
        monotonicTimestamp: W(),
        args: i || [],
        apiVersionTag: e
      };
    }(e, n, i);
  return g.storeCallbackInformation(s.uuid, {
    name: n,
    calledAt: s.timestamp
  }), t("Message %s information: %o", ae(s), {
    actionName: n,
    args: i
  }), z(o, s);
}
const _ = b.extend("processIncomingMessage");
const V = b.extend("processAuthBridgeMessage");
function q(e, n) {
  var i, t;
  const o = V;
  if (!e || !e.data || "object" != typeof e.data) return void o("Unrecognized message format received by app, message being ignored. Message: %o", e);
  const {
      args: s
    } = e.data,
    [, r] = null != s ? s : [],
    a = (() => {
      try {
        return JSON.parse(r);
      } catch (e) {
        return null;
      }
    })();
  if (!a || "object" != typeof a || "NestedAppAuthResponse" !== a.messageType) return void o("Unrecognized data format received by app, message being ignored. Message: %o", e);
  const d = e.source || (null === (i = null == e ? void 0 : e.originalEvent) || void 0 === i ? void 0 : i.source),
    c = e.origin || (null === (t = null == e ? void 0 : e.originalEvent) || void 0 === t ? void 0 : t.origin);
  d ? H(d, c) ? (y.topWindow && !y.topWindow.closed && d !== y.topWindow || (y.topWindow = d, y.topOrigin = c), y.topWindow && y.topWindow.closed && (y.topWindow = null, y.topOrigin = null), te(y.topWindow), n(r)) : o("Message being ignored by app because it is either coming from the current window or a different window with an invalid origin") : o("Message being ignored by app because it is coming for a target that is null");
}
const D = b.extend("shouldProcessIncomingMessage");
function H(n, i) {
  return e(this, void 0, void 0, function* () {
    if (y.currentWindow && n === y.currentWindow) return D("Should not process message because it is coming from the current window"), !1;
    if (y.currentWindow && y.currentWindow.location && i && i === y.currentWindow.location.origin) return !0;
    {
      let e;
      try {
        e = new URL(i);
      } catch (e) {
        return D("Message has an invalid origin of %s", i), !1;
      }
      const n = yield M(e);
      return n || D("Message has an invalid origin of %s", i), n;
    }
  });
}
const J = b.extend("handleIncomingMessageFromParent");
function B(e, n) {
  if (n) {
    const i = [...e].find(([e, i]) => e.toString() === n.toString());
    if (i) return i[0];
  }
}
function K(e, n) {
  const i = B(n, e.uuid);
  i && n.delete(i), e.uuid ? k.legacyMessageIdsToUuidMap = {} : delete k.legacyMessageIdsToUuidMap[e.id];
}
function G(e) {
  const n = J,
    i = W();
  if ("id" in e.data && "number" == typeof e.data.id) {
    const t = e.data,
      o = f(t),
      s = function (e) {
        const n = J;
        if (!e.uuid) return k.legacyMessageIdsToUuidMap[e.id];
        {
          const n = e.uuid,
            i = B(k.callbacks, n);
          if (i) return i;
          const t = B(k.promiseCallbacks, n);
          if (t) return t;
          const o = B(k.portCallbacks, n);
          if (o) return o;
        }
        n("Received message %s that failed to produce a callbackId", ae(e));
      }(o);
    if (s) {
      const t = k.callbacks.get(s);
      n("Received a response from parent for message %s", s.toString()), g.handlePerformanceMetrics(s, o, n, i), t && (n("Invoking the registered callback for message %s with arguments %o", s.toString(), o.args), t.apply(null, [...o.args, o.isPartialResponse]), function (e) {
        return !0 === e.data.isPartialResponse;
      }(e) || (n("Removing registered callback for message %s", s.toString()), K(o, k.callbacks)));
      const r = k.promiseCallbacks.get(s);
      r && (n("Invoking the registered promise callback for message %s with arguments %o", s.toString(), o.args), r(o.args), n("Removing registered promise callback for message %s", s.toString()), K(o, k.promiseCallbacks));
      const a = k.portCallbacks.get(s);
      if (a) {
        let i;
        n("Invoking the registered port callback for message %s with arguments %o", s.toString(), o.args), e.ports && e.ports[0] instanceof MessagePort && (i = e.ports[0]), a(i, o.args), n("Removing registered port callback for message %s", s.toString()), K(o, k.portCallbacks);
      }
      o.uuid && (k.legacyMessageIdsToUuidMap = {});
    }
  } else if ("func" in e.data && "string" == typeof e.data.func) {
    const t = e.data;
    g.handleOneWayPerformanceMetrics(t, n, i), n('Received a message from parent %s, action: "%s"', ae(t), t.func), l(t.func, t.args);
  } else n("Received an unknown message: %O", e);
}
const X = b.extend("handleIncomingMessageFromChild");
function Y() {
  return y.topWindow !== y.parentWindow;
}
function Z(e) {
  return e === y.topWindow && Y() ? k.topMessageQueue : e === y.parentWindow ? k.parentMessageQueue : e === y.childWindow ? k.childMessageQueue : [];
}
function ee(e) {
  return e === y.topWindow && Y() ? y.topOrigin : e === y.parentWindow ? y.parentOrigin : e === y.childWindow ? y.childOrigin : null;
}
function ne(e) {
  return e === y.topWindow && Y() ? "top" : e === y.parentWindow ? "parent" : e === y.childWindow ? "child" : null;
}
const ie = b.extend("flushMessageQueue");
function te(e) {
  const n = ee(e),
    i = Z(e),
    t = ne(e);
  for (; e && n && i.length > 0;) {
    const o = i.shift();
    if (o) {
      const i = p(o);
      ie("Flushing message %s from %s message queue via postMessage.", ae(i), t), e.postMessage(i, n);
    }
  }
}
function oe(e, n) {
  let i;
  i = y.currentWindow.setInterval(() => {
    0 === Z(e).length && (clearInterval(i), n());
  }, 100);
}
function se(e, n, i, t) {
  const o = y.childWindow,
    s = function (e, n, i, t) {
      return {
        id: e,
        uuid: n,
        args: i || [],
        isPartialResponse: t
      };
    }(e, n, i, t),
    r = w(s),
    a = ee(o);
  o && a && o.postMessage(r, a);
}
function re(e, n) {
  const i = y.childWindow,
    t = function (e, n) {
      return {
        func: e,
        args: n || []
      };
    }(e, n),
    o = ee(i);
  i && o ? i.postMessage(t, o) : Z(i).push(t);
}
function ae(e) {
  return "uuidAsString" in e ? `${e.uuidAsString} (legacy id: ${e.id})` : "uuid" in e && void 0 !== e.uuid ? `${e.uuid.toString()} (legacy id: ${e.id})` : `legacy id: ${e.id} (no uuid)`;
}
export { y as Communication, C as callFunctionInHost, P as callFunctionInHostAndHandleResponse, I as initializeCommunication, x as requestPortFromParentWithVersion, j as sendAndHandleSdkError, E as sendAndHandleStatusAndReason, O as sendAndHandleStatusAndReasonWithDefaultError, R as sendAndUnwrap, re as sendMessageEventToChild, N as sendMessageToParent, S as sendMessageToParentAsync, U as sendNestedAuthRequestToTopWindow, T as uninitializeCommunication, oe as waitForMessageQueue };