import { Buffer as e } from "../../../../node_modules/.pnpm/rollup-plugin-node-polyfills@0.2.1/node_modules/rollup-plugin-node-polyfills/polyfills/buffer-es6.js";
import { minAdaptiveCardVersion as t } from "../public/constants.js";
import n from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/v4.js";
import r from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/validate.js";
function o(e) {
  return (e, t) => {
    if (!e) throw new Error(t);
  };
}
function i(e, t) {
  if ("string" != typeof e || "string" != typeof t) return NaN;
  const n = e.split("."),
    r = t.split(".");
  function o(e) {
    return /^\d+$/.test(e);
  }
  if (!n.every(o) || !r.every(o)) return NaN;
  for (; n.length < r.length;) n.push("0");
  for (; r.length < n.length;) r.push("0");
  for (let e = 0; e < n.length; ++e) if (Number(n[e]) != Number(r[e])) return Number(n[e]) > Number(r[e]) ? 1 : -1;
  return 0;
}
function u() {
  return n();
}
function c(e) {
  return Object.keys(e).forEach(t => {
    null !== e[t] && void 0 !== e[t] && "object" == typeof e[t] && c(e[t]);
  }), Object.freeze(e);
}
function s(e, t, ...n) {
  const r = e(...n);
  return r.then(e => {
    t && t(void 0, e);
  }).catch(e => {
    t && t(e);
  }), r;
}
function l(e, t, ...n) {
  const r = e(...n);
  return r.then(() => {
    t && t(null);
  }).catch(e => {
    t && t(e);
  }), r;
}
function f(e, t, ...n) {
  const r = e(...n);
  return r.then(e => {
    t && t(null, e);
  }).catch(e => {
    t && t(e, null);
  }), r;
}
function a(e, t, n) {
  return new Promise((r, o) => {
    const i = setTimeout(o, t, n);
    e().then(e => {
      clearTimeout(i), r(e);
    }).catch(e => {
      clearTimeout(i), o(e);
    });
  });
}
function p(e) {
  const t = new URL("https://teams.microsoft.com/l/entity/" + encodeURIComponent(e.appId.toString()) + "/" + encodeURIComponent(e.pageId));
  return e.webUrl && t.searchParams.append("webUrl", e.webUrl.toString()), (e.chatId || e.channelId || e.subPageId) && t.searchParams.append("context", JSON.stringify({
    chatId: e.chatId,
    channelId: e.channelId,
    subEntityId: e.subPageId
  })), t.toString();
}
function d(e) {
  return !(i(`${e.majorVersion}.${e.minorVersion}`, `${t.majorVersion}.${t.minorVersion}`) >= 0);
}
function m(e) {
  return "https:" === e.protocol;
}
function h(t, n) {
  return new Promise((r, o) => {
    if (t || o("MimeType cannot be null or empty."), n || o("Base64 string cannot be null or empty."), t.startsWith("image/")) {
      const e = atob(n),
        o = new Uint8Array(e.length);
      for (let t = 0; t < e.length; t++) o[t] = e.charCodeAt(t);
      r(new Blob([o], {
        type: t
      }));
    }
    const i = e.from(n, "base64").toString();
    r(new Blob([i], {
      type: t
    }));
  });
}
function b(e) {
  return new Promise((t, n) => {
    0 === e.size && n(new Error("Blob cannot be empty."));
    const r = new FileReader();
    r.onloadend = () => {
      r.result ? t(r.result.toString().split(",")[1]) : n(new Error("Failed to read the blob"));
    }, r.onerror = () => {
      n(r.error);
    }, r.readAsDataURL(e);
  });
}
function w() {
  if (g()) throw new Error("window object undefined at SSR check");
  return window;
}
function g() {
  return "undefined" == typeof window;
}
function y(e, t) {
  if (I(e) || !function (e) {
    return e.length < 256 && e.length > 4;
  }(e) || !function (e) {
    for (let t = 0; t < e.length; t++) {
      const n = e.charCodeAt(t);
      if (n < 32 || n > 126) return !1;
    }
    return !0;
  }(e)) throw t || new Error("id is not valid.");
}
function j(e, t) {
  const n = e.toString().toLocaleLowerCase();
  if (I(n)) throw new Error("Invalid Url");
  if (n.length > 2048) throw new Error("Url exceeds the maximum size of 2048 characters");
  if (!m(e)) throw new Error("Url should be a valid https url");
}
function E(e) {
  const t = document.createElement("a");
  return t.href = e, new URL(t.href);
}
function I(e) {
  return new RegExp(`${/<script[^>]*>|&lt;script[^&]*&gt;|%3Cscript[^%]*%3E/gi.source}|${/<\/script[^>]*>|&lt;\/script[^&]*&gt;|%3C\/script[^%]*%3E/gi.source}`, "gi").test(e);
}
function v(e) {
  if (!e) throw new Error("id must not be empty");
  if (!1 === r(e)) throw new Error("id must be a valid UUID");
}
const U = !!performance && "now" in performance;
function O() {
  return U ? performance.now() + performance.timeOrigin : void 0;
}
function S(e, t = 0) {
  if (t > 1e3) return !1;
  if (void 0 === e || "boolean" == typeof e || "number" == typeof e || "bigint" == typeof e || "string" == typeof e || null === e) return !0;
  if (Array.isArray(e)) return e.every(e => S(e, t + 1));
  return !("object" != typeof e || "[object Object]" !== Object.prototype.toString.call(e) || Object.getPrototypeOf(e) !== Object.prototype && null !== Object.getPrototypeOf(e)) && Object.keys(e).every(n => S(e[n], t + 1));
}
export { h as base64ToBlob, s as callCallbackWithErrorOrResultFromPromiseAndReturnPromise, f as callCallbackWithErrorOrResultOrNullFromPromiseAndReturnPromise, l as callCallbackWithSdkErrorFromPromiseAndReturnPromise, i as compareSDKVersions, p as createTeamsAppLink, c as deepFreeze, E as fullyQualifyUrlString, u as generateGUID, b as getBase64StringFromBlob, O as getCurrentTimestamp, o as getGenericOnCompleteHandler, I as hasScriptTags, g as inServerSideRenderingEnvironment, d as isHostAdaptiveCardSchemaVersionUnsupported, S as isPrimitiveOrPlainObject, m as isValidHttpsURL, a as runWithTimeout, w as ssrSafeWindow, y as validateId, j as validateUrl, v as validateUuid };